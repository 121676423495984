import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./register-service-worker";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
  <div>
  <h3>Application title</h3>
  <BrowserRouter basename={baseUrl}>
    <Switch>
      <Route exact path="/" component={MainLayout} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  </BrowserRouter>
  </div>
  , rootElement);

registerServiceWorker();

function MainLayout() {
  return (
    <p>
        Hello there! Whats up, buddy?
    </p>
  );
}

function PageNotFound() {
  const history = useHistory();
  history.replace("/");
  return null;
}
